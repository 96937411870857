import { CompanyDetailsAction } from '../../types/company/details';

export const fetchCompanyOwnerAction = companyId => ({
  type: CompanyDetailsAction.FETCH_COMPANY_OWNER,
  payload: {
    companyId
  }
});

export const updateCompanyOwnerAction = owners => ({
  type: CompanyDetailsAction.UPDATE_COMPANY_OWNER,
  payload: {
    owners
  }
});

export const setLastInActivatedCompanyAction = (id, record, index) => ({
  type: CompanyDetailsAction.SET_LAST_INACTIVE_COMPANY,
  payload: {
    lastInActivatedCompanyId: id,
    lastInActivatedCompanyRecord: record,
    lastInActivatedCompanyRecordIndex: index
  }
});

export const changeCompanySector = (sectorId, companyId) => ({
  type: CompanyDetailsAction.CHANGE_COMPANY_SECTOR,
  payload: {
    sectorId,
    companyId
  }
});

export const getCompanyLeadership = companyId => ({
  type: CompanyDetailsAction.GET_COMPANY_LEADERSHIP,
  payload: {
    companyId
  }
});

export const setCompanyLeaderships = (leaderships, leadershipsCount) => ({
  type: CompanyDetailsAction.SET_COMPANY_LEADERSHIP,
  payload: {
    leaderships,
    leadershipsCount
  }
});

export const getCompanyCompetitors = companyId => ({
  type: CompanyDetailsAction.GET_COMPANY_COMPETITORS,
  payload: {
    companyId
  }
});

export const setCompanyCompetitors = (competitors, competitorsCount) => ({
  type: CompanyDetailsAction.SET_COMPANY_COMPETITORS,
  payload: {
    competitors,
    competitorsCount
  }
});

export const addCompanyCompetitor = (companyId, competitorId) => ({
  type: CompanyDetailsAction.ADD_COMPANY_COMPETITOR,
  payload: { companyId, competitorId }
});

export const removeCompanyCompetitor = (companyId, competitorId) => ({
  type: CompanyDetailsAction.REMOVE_COMPANY_COMPETITOR,
  payload: { companyId, competitorId }
});

export const getCompanyFundingRounds = companyId => ({
  type: CompanyDetailsAction.GET_COMPANY_FUNDING_ROUNDS,
  payload: {
    companyId
  }
});

export const setCompanyFundingRounds = fundingRounds => ({
  type: CompanyDetailsAction.SET_COMPANY_FUNDING_ROUNDS,
  payload: {
    fundingRounds
  }
});

export const getCompanyTopRatedEmployees = (companyId, offset) => ({
  type: CompanyDetailsAction.GET_COMPANY_TOP_RATED_EMPLOYEES,
  payload: {
    companyId,
    offset
  }
});

export const getCompanyMoreTopRatedEmployees = (companyId, offset) => ({
  type: CompanyDetailsAction.GET_COMPANY_MORE_TOP_RATED_EMPLOYEES,
  payload: {
    companyId,
    offset
  }
});

export const setCompanyTopRatedEmployees = (
  topRatedEmployees,
  topRatedEmployeesCount
) => ({
  type: CompanyDetailsAction.SET_COMPANY_TOP_RATED_EMPLOYEES,
  payload: {
    topRatedEmployees,
    topRatedEmployeesCount
  }
});

export const setCompanyMoreTopRatedEmployees = topRatedEmployees => ({
  type: CompanyDetailsAction.SET_COMPANY_MORE_TOP_RATED_EMPLOYEES,
  payload: {
    topRatedEmployees
  }
});

export const getCompanyGraph = payload => ({
  type: CompanyDetailsAction.GET_COMPANY_GRAPH,
  payload
});

export const setCompanyGraph = payload => ({
  type: CompanyDetailsAction.SET_COMPANY_GRAPH,
  payload
});

export const getCompanyGraphTypes = payload => ({
  type: CompanyDetailsAction.GET_COMPANY_GRAPH_TYPES,
  payload
});

export const setCompanyGraphTypes = payload => ({
  type: CompanyDetailsAction.SET_COMPANY_GRAPH_TYPES,
  payload
});

export const getCompetitorsGraph = payload => ({
  type: CompanyDetailsAction.GET_COMPETITORS_GRAPH,
  payload
});

export const setCompetitorsGraph = payload => ({
  type: CompanyDetailsAction.SET_COMPETITORS_GRAPH,
  payload
});

export const updateCompanySectorIcAction = (companyId, newSectorIc) => ({
  type: CompanyDetailsAction.UPDATE_COMPANY_SECTOR_IC,
  payload: {
    companyId,
    newSectorIc
  }
});

export const updateCompanyProjectedRaiseDateAction = (companyId, newProjectedRaiseDate) => ({
  type: CompanyDetailsAction.UPDATE_COMPANY_PROJECTED_RAISE_DATE,
  payload: {
    companyId,
    newProjectedRaiseDate
  }
});

export const updateCompanyFinancialsAction = (companyId, newFinancials) => ({
  type: CompanyDetailsAction.UPDATE_COMPANY_FINANCIALS,
  payload: {
    companyId,
    newFinancials
  }
});

export const updateCompanyNextStepAction = (companyId, newNextStep) => ({
  type: CompanyDetailsAction.UPDATE_COMPANY_NEXT_STEP,
  payload: {
    companyId,
    newNextStep
  }
});

export const updateCompanyStatusAction = (companyId, newStatus) => ({
  type: CompanyDetailsAction.UPDATE_COMPANY_STATUS,
  payload: {
    companyId,
    newStatus
  }
});

export const updateCompanyUrgentAction = (companyId, newUrgent) => ({
  type: CompanyDetailsAction.UPDATE_COMPANY_URGENT,
  payload: {
    companyId,
    newUrgent
  }
});

export const localUpdateCompanySectorIcAction = (companyId, newSectorIc) => ({
  type: CompanyDetailsAction.LOCAL_UPDATE_COMPANY_SECTOR_IC,
  payload: {
    companyId,
    newSectorIc
  }
});

export const localUpdateCompanyProjectedRaiseDateAction = (companyId, newProjectedRaiseDate) => ({
  type: CompanyDetailsAction.LOCAL_UPDATE_COMPANY_PROJECTED_RAISE_DATE,
  payload: {
    companyId,
    newProjectedRaiseDate
  }
});

export const localUpdateCompanyFinancialsAction = (companyId, newFinancials) => ({
  type: CompanyDetailsAction.LOCAL_UPDATE_COMPANY_FINANCIALS,
  payload: {
    companyId,
    newFinancials
  }
});

export const localUpdateCompanyNextStepAction = (companyId, newNextStep) => ({
  type: CompanyDetailsAction.LOCAL_UPDATE_COMPANY_NEXT_STEP,
  payload: {
    companyId,
    newNextStep
  }
});

export const localUpdateCompanyStatusAction = (companyId, newStatus) => ({
  type: CompanyDetailsAction.LOCAL_UPDATE_COMPANY_STATUS,
  payload: {
    companyId,
    newStatus
  }
});

export const localUpdateCompanyUrgentAction = (companyId, newUrgent) => ({
  type: CompanyDetailsAction.LOCAL_UPDATE_COMPANY_URGENT,
  payload: {
    companyId,
    newUrgent
  }
});
