import { CompanyDetailsAction } from '../../types/company/details';

const INITIAL_STATE = {
  owners: [],
  lastInActivatedCompanyId: undefined,
  lastInActivatedCompanyRecord: undefined,
  lastInActivatedCompanyRecordIndex: undefined,
  leaderships: [],
  leadershipsCount: 0,
  competitors: [],
  competitorsCount: 0,
  fundingRounds: [],
  topRatedEmployees: [],
  moreTopRatedEmployees: [],
  loadingMoreTopRatedEmployees: false,
  topRatedEmployeesCount: 0,
  graph: {
    external: {
      types: {},
      data: {},
      competitorsData: {}
    },
    internal: {
      types: {},
      data: {},
      competitorsData: {}
    }
  }
};

const companyDetails = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CompanyDetailsAction.UPDATE_COMPANY_OWNER:
      return { ...state, ...action.payload };
    case CompanyDetailsAction.SET_LAST_INACTIVE_COMPANY:
      return { ...state, ...action.payload };
    case CompanyDetailsAction.SET_COMPANY_LEADERSHIP:
      return { ...state, ...action.payload };
    case CompanyDetailsAction.SET_COMPANY_COMPETITORS:
      return { ...state, ...action.payload };
    case CompanyDetailsAction.SET_COMPANY_FUNDING_ROUNDS:
      return { ...state, ...action.payload };
    case CompanyDetailsAction.SET_COMPANY_TOP_RATED_EMPLOYEES:
      return { ...state, ...action.payload };
    case CompanyDetailsAction.GET_COMPANY_MORE_TOP_RATED_EMPLOYEES:
      return { ...state, loadingMoreTopRatedEmployees: true };
    case CompanyDetailsAction.SET_COMPANY_MORE_TOP_RATED_EMPLOYEES:
      return {
        ...state,
        moreTopRatedEmployees: [
          ...(state.moreTopRatedEmployees.length
            ? state.moreTopRatedEmployees
            : state.topRatedEmployees),
          ...(action.payload?.topRatedEmployees || [])
        ],
        loadingMoreTopRatedEmployees: false
      };

    case CompanyDetailsAction.SET_COMPANY_GRAPH_TYPES: {
      const key = action.payload.internal ? 'internal' : 'external';
      return {
        ...state,
        graph: {
          ...state.graph,
          [key]: {
            ...state.graph[key],
            types: action.payload.graphTypes
          }
        }
      };
    }

    case CompanyDetailsAction.SET_COMPANY_GRAPH: {
      const key = action.payload.internal ? 'internal' : 'external';
      return {
        ...state,
        graph: {
          ...state.graph,
          [key]: {
            ...state.graph[key],
            data: {
              ...state.graph[key].data,
              [action.payload.graphType]: action.payload.graphData
            }
          }
        }
      };
    }

    case CompanyDetailsAction.SET_COMPETITORS_GRAPH: {
      const key = action.payload.internal ? 'internal' : 'external';
      return {
        ...state,
        graph: {
          ...state.graph,
          [key]: {
            ...state.graph[key],
            competitorsData: {
              ...state.graph[key].competitorsData,
              [action.payload.graphType]: action.payload.graphData
            }
          }
        }
      };
    }

    default:
      return state;
  }
};

export default companyDetails;
